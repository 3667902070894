import moment from 'moment';
import { cva } from 'class-variance-authority';
import { useTranslation } from 'react-i18next';

export function AllotmentButton ({ children, onClick, type = 'button', id, disabled }) {
  const allotmentButton = cva([
    'w-52 px-4 py-2 text-xs font-medium bg-gray-50 rounded-md transition',
  ], {
    variants: {
      disabled: {
        true: 'cursor-not-allowed opacity-50',
      }
    }
  });

  return (
    <button
      onClick={!disabled ? onClick : undefined}
      type={type}
      id={id}
      className={allotmentButton({ disabled })}
    >
      {children}
    </button>
  );
}

export default function AllotmentsEditorMonthSelector({ currentMonth, setCurrentMonth, isDisabled, reload }) {
  const { t } = useTranslation('allotments');
  const month = moment().month();

  return (
    <div className="flex justify-between items-center">
      <AllotmentButton
        onClick={() => {
          setCurrentMonth(currentMonth - 1);
          reload();
        }}
        disabled={isDisabled}
      >
        <i className="fal fa-arrow-left mr-1.5" />
        {moment().month(currentMonth - 1).format('MMMM YYYY')}
      </AllotmentButton>

      <div className="text-xs font-medium bg-gray-50 rounded-md py-2 px-8 border">
        <div className="flex space-x-1.5 items-center">
          <div>
            <i className="far fa-calendar-alt" />
          </div>

          <div id={moment().month(currentMonth).format('MMMM YYYY').toLowerCase().split(' ').join('-')}>
            {moment().month(currentMonth).format('MMMM YYYY')}
          </div>

          {!isDisabled && currentMonth !== month && (
            <>
              <div>
                &bull;
              </div>

              <button
                className="text-blue-500 font-medium border-none"
                onClick={() => setCurrentMonth(month)}
              >
                {t('monthSelector.goBack', {month: moment().format('MMMM YYYY')})}
              </button>
            </>
          )}
        </div>
      </div>

      <AllotmentButton
        id="next-month"
        onClick={() => {
          setCurrentMonth(currentMonth + 1);
          reload();
        }}
        disabled={isDisabled}
      >
        {moment().month(currentMonth + 1).format('MMMM YYYY')}
        <i className="fal fa-arrow-right ml-1.5"/>
      </AllotmentButton>
    </div>
  );
}

import { useCallback, useEffect, useState } from 'react';
import { Stack } from '@koob/margaret';
import { Button } from 'components';
import { cloneDeep, omit } from 'lodash';
import { useTranslation } from 'react-i18next';
import { IoWarningOutline } from 'react-icons/io5';
import styled, { css } from 'styled-components';
import { Text } from 'ui';
import { formatCurrency, roomTags } from 'utils';
import DmcBookingHotelInfo from './DmcContractHotelInfo';
import DmcBookingHotelRoomsInfo from './DmcContractHotelRoomsInfo';

import { useBooking, useTripDesigner } from 'hooks';
import { useParams, useNavigate } from 'react-router-dom';
import { post } from '../../api/node';
import { useAsync } from 'react-async';
import { differenceInDays } from 'date-fns';
import { useDeepCompareEffect } from 'react-use';
import { getTravelerComposition } from 'providers/BookingProvider';
import { KoobPlaceholder, PromotionTag, RequiredPromotionTag } from '@koob/ui';
import KoobLoaderText from '@koob/ui/src/components/KoobLoaderText';
import formatHotelRoomPossibilities from '@koob/checkout/src/utilities/formatHotelRoomPossibilities';
import CheckoutHotelRoom from '@koob/checkout/src/components/CheckoutHotelRoom';

const Content = styled.div`
  position: relative;
  width: 100%;
`;

const SectionTitle = styled(Text).attrs({ type: 'h2' })`
  font-weight: bold;
`;

export const CompositionBed = styled(Stack).attrs({
  alignY: 'center',
  gutterSize: 0.25,
})`
  font-size: 0.75rem;
  padding: ${({ theme }) => theme.spacing(0.25)};
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.borderRadius.extraLarge};
  margin-top: 5px;

  ${props =>
    props.greenOption &&
    css`
      background-color: #eef8f2;
      color: #73c992;
    `}

  ${props =>
    props.orangeOption &&
    css`
      background-color: #ffe3e3;
      color: #ea5d5d !important;
    `}
`;

export const getRoomsAvailable = ({ id, filters }) =>
  post(`hotels/${id}/roomsAvailable`, filters);

const RoomComponent = ({
  numberOfNights,
  room,
  selectedPossibilityIndex,
  isSelected,
  bedIndex,
  updateSelectedRoom,
  promoList,
  setCurrentSelectedPossibilityIndex,
  selectedRoomsCount
}) => {
  const { t } = useTranslation('bookings');

  const currentPossibility = selectedPossibilityIndex === undefined
    ? room
    : room?.variants?.find(variant => variant.originalIndex === selectedPossibilityIndex) ?? room;

  const currentBed = currentPossibility.bedChoices[bedIndex];

  const supplementTypes = ['supplement', 'supplement_percent'];
  const hasGlobalPromo = currentPossibility.bedChoices.some(
    item =>
      (item.totalPriceWithPromo !== item.totalPriceWithoutPromo &&
        !supplementTypes.includes(item?.promotionInfo?.kind)) ||
      item?.promotionInfo?.kind === 'amenity',
  );
  const koediaPromos = currentPossibility?.bedChoices[0]?.promotions?.filter(item =>
    Boolean(item),
  );

  const renderPromotions = () => {
    const allPromotions =
      currentPossibility?.allPromotions
        ?.flatMap(promotionCombinations => {
          return Object.values(promotionCombinations).flatMap(
            promotionCombination => promotionCombination,
          );
        })
        .filter((value, index, self) => self.indexOf(value) === index) ?? [];
    const allSupplementsMandatory =
      currentPossibility.bedChoices[bedIndex]?.mandatorySupplements ?? [];
    const optionalSupplements = currentPossibility.bedChoices[bedIndex]?.optionalSupplements;
    const allSupplements = Object.entries(
      allSupplementsMandatory.reduce((acc, val) => {
        acc[val] = (acc[val] || 0) + 1;
        return acc;
      }, {}),
    ).map(([key, value]) => `${key} (x${value})`);
    if (
      allPromotions.length > 0 ||
      allSupplements.length > 0 ||
      promoList?.length > 0
    ) {
      return (
        <>
          {hasGlobalPromo &&
            allPromotions.length > 0 &&
            (allPromotions || []).map((name, index) => (
              <PromotionTag key={index} name={name} />
            ))}

          {optionalSupplements?.map(({ name }, index) => (
            <PromotionTag key={index} name={name} />
          ))}

          {allSupplements.length > 0 &&
            allSupplements?.map((supplement, index) => (
              <RequiredPromotionTag
                key={index}
                name={supplement}
                label={t('requiredSupplement')} 
              />
            ))}
        </>
      );
    }
    return null;
  };

  return (
    <CheckoutHotelRoom
      scope="dmc"
      room={currentPossibility}
      parentRoom={room}
      id={`${currentPossibility?.name?.toLowerCase().split(' ').join('-')}-${selectedRoomsCount}`}
      selectedRoomIndex={selectedPossibilityIndex}
      currentBed={currentBed}
      price={{
        total: currentBed?.totalPriceWithPromo,
        currency: currentBed?.currency,
        nightsCount: numberOfNights,
      }}
      isSelected={isSelected}
      onSelect={(newIndex) => {
        updateSelectedRoom(newIndex, 0);
        setCurrentSelectedPossibilityIndex(newIndex);
      }}
    >
      <div className="flex flex-wrap gap-3">
        {renderPromotions()}

        {((room?.supplier === 'Koedia' && koediaPromos) || []).map(
          (name, index) =>
            name !== null && (<PromotionTag key={index} name={name} />),
        )}
      </div>
    </CheckoutHotelRoom>
  );
};

const SelectRoomsForm = ({
  onSelectHotelsRooms,
  hotel,
  setRequestId,
  setExpirationDate,
}) => {
  const { t } = useTranslation('bookings');
  const {
    filters,
    nationalities,
    replaceBookingId,
    setBedIndexes,
    bedIndexes,
  } = useBooking();
  const navigate = useNavigate();
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [currentSelectedRoomIndex, setCurrentSelectedRoomIndex] = useState(0);
  const [currentSelectedPossibilityIndex, setCurrentSelectedPossibilityIndex] =
    useState(0);
  const [currentSelectedBedIndex, setCurrentSelectedBedIndex] = useState(0);
  const { rooms = [] } = filters;
  const [possibilities, setPossibilities] = useState([]);
  const [initialPossibilities, setInitialPossibilities] = useState([]);
  const { hotelId } = useParams();
  const numberOfNights = differenceInDays(
    new Date(filters.endAt),
    new Date(filters.startAt),
  );
  const [optionalPromo, setOptionalPromo] = useState([]);
  const { basket, tripFilters, updateInBasket } = useTripDesigner();
  const [selectedPromo, setSelectedPromo] = useState();

  const basketHotel = basket?.find(
    basketExperience => basketExperience?.internalId === filters?.internalId,
  );

  const tagsKey = tags => {
    return tags
      ?.map(t => t.label?.toLowerCase()?.replace(/ /g, ''))
      ?.sort()
      ?.join('#');
  };

  useDeepCompareEffect(() => {
    const selectedBed = { [currentSelectedRoomIndex]: currentSelectedBedIndex };

    if (bedIndexes.length > 0) {
      const indexToUpdate = bedIndexes.findIndex(item =>
        item.hasOwnProperty(currentSelectedRoomIndex),
      );

      if (indexToUpdate !== -1) {
        setBedIndexes(prev => {
          const updatedBedIndexes = [...prev];
          updatedBedIndexes[indexToUpdate] = selectedBed;
          return updatedBedIndexes;
        });
      } else {
        setBedIndexes(prev => [...prev, selectedBed]);
      }
    } else {
      setBedIndexes([selectedBed]);
    }
  }, [currentSelectedBedIndex, currentSelectedRoomIndex]);

  const isLastRoomOnRequest =
    Number(rooms.length) - Number(selectedRooms.length) === 1
      ? possibilities[currentSelectedRoomIndex]?.allotmentAvailable
      : false;
  const isBookingOnRequest =
    selectedRooms.length > 0
      ? selectedRooms.some(room => room?.allotmentAvailable)
      : possibilities[currentSelectedRoomIndex]?.allotmentAvailable;

  const currentSelectedRoom = initialPossibilities[currentSelectedRoomIndex];

  const { data, isLoading } = useAsync(
    {
      promiseFn: getRoomsAvailable,
      id: hotelId ?? hotel.id,
      filters: {
        startAt: filters.startAt,
        endAt: filters.endAt,
        rooms: filters?.rooms?.map(room => ({
          ...omit(room, 'children'),
          firstAdultNationality: room?.firstAdultNationality?.value,
        })),
        replaceBookingId: replaceBookingId ?? undefined,
        tripId: filters.tripId,
      },
    },
    false,
  );

  //find the right compositions in compatibilties room
  function findRoomByRoomNumber(rooms, roomNumber) {
    const response = [];
    for (const room of rooms) {
      if (getRoomNumberFromBedChoices(room.bedChoices) === roomNumber) {
        response.push(room);
      }
      for (const compatibleRoom of room.compatibleRoomCompositions) {
        const foundRoom = findRoomByRoomNumber([compatibleRoom], roomNumber);
        if (foundRoom) {
          response.push(...foundRoom);
        }
      }
    }
    return response.filter(item => Boolean(item?.name));
  }

  function getRoomNumberFromBedChoices(bedChoices) {
    for (const choice of bedChoices) {
      if (choice.promotionInfo && choice.promotionInfo.roomNumber) {
        return choice.promotionInfo.roomNumber;
      }
    }
    return null;
  }

  const updateGroupedCompatibilityRoom = useCallback(
    (rooms, referencesRooms) => {
      rooms.flatMap(room => {
        if (
          room.compatibleRoomCompositions.length > 0 &&
          room.supplier === 'Koob'
        ) {
          if (
            room.compatibleRoomCompositions[0].compatibleRoomCompositions
              .length > 0
          ) {
            updateGroupedCompatibilityRoom(
              room.compatibleRoomCompositions[0].compatibleRoomCompositions,
              referencesRooms,
            );
          }
          const groupedCompatibilityRoom = {};
          room.compatibleRoomCompositions.forEach(compatibleRoom => {
            referencesRooms.forEach(referenceRoom => {
              if (
                referenceRoom.originalIndexes.includes(
                  compatibleRoom.originalIndex,
                )
              ) {
                groupedCompatibilityRoom[referenceRoom.originalIndex] =
                  referenceRoom;
              }
            });
          });
          room.compatibleRoomCompositions = Object.values(
            groupedCompatibilityRoom,
          );
        }
        return {};
      });
    },
    [],
  );

  const getRefundableTag = tags => {
    return (tags ?? []).find(
      tag =>
        tag.label === roomTags.REFUNDABLE ||
        tag.label === roomTags.NON_REFUNDABLE,
    )?.label;
  };

  const updateOriginalCompatibilityRoom = useCallback(
    (rooms, referencesRooms) => {
      rooms.flatMap(room => {
        if (room.compatibleRoomCompositions.length > 0) {
          updateOriginalCompatibilityRoom(
            room.compatibleRoomCompositions,
            referencesRooms,
          );
        }
        if (room.originalIndex === undefined) {
          if (room.supplier !== 'Koob') {
            const originalRoom = referencesRooms.find(rRef => {
              if (rRef.supplier === 'Koob') {
                return false;
              }
              return (
                rRef.name === room.name &&
                rRef.supplier === room.supplier &&
                rRef.source === room.source &&
                getRefundableTag(rRef.bedChoices[0].tags) ===
                  getRefundableTag(room.bedChoices[0].tags)
              );
            });
            if (originalRoom) {
              room.originalIndex = originalRoom.originalIndex;
            }
          } else {
            const originalRoom = referencesRooms.find(rRef => {
              if (rRef.supplier !== 'Koob') {
                return false;
              }
              const rBedsIds = rRef.bedChoices
                .map(bed => {
                  return `${bed.promotions.join('_')}`;
                })
                .join('_');
              const bedIds = room.bedChoices
                .map(bed => {
                  return `${bed.promotions.join('_')}`;
                })
                .join('_');

              return (
                rRef.supplierSpecificInformations.hotelRoomId ===
                  room.supplierSpecificInformations.hotelRoomId &&
                rBedsIds === bedIds
              );
            });

            room.originalIndex = originalRoom?.originalIndex;
            room.allPromotions = originalRoom?.allPromotions;
          }
        }
        return {};
      });
    },
    [],
  );

  const areCompositionsEqual = useCallback((composition1, composition2) => {
    const isSameCompositions =
      composition1.name === composition2.name &&
      composition1.supplierCode === composition2.supplierCode &&
      composition1.source === composition2.source &&
      composition1.bedChoices[0].totalPriceWithPromo ===
        composition2.bedChoices[0].totalPriceWithPromo &&
      tagsKey(composition1.bedChoices[0].tags) ===
        tagsKey(composition2.bedChoices[0].tags);

    return isSameCompositions;
  }, []);

  const getRoomKey = useCallback((room, depth) => {
    return `${room.name}_${room.supplierCode || room.supplier}_${room.source}_${
      room.bedChoices[0].totalPriceWithPromo
    }_${depth}_${tagsKey(room.bedChoices[0].tags)}`;
  }, []);

  const mergeCompatibleRoomCompositions = useCallback(
    (targetRoom, compatibleRoomCompositions, depth) => {
      targetRoom.compatibleRoomCompositions =
        targetRoom.compatibleRoomCompositions || [];
      compatibleRoomCompositions.forEach(composition => {
        const existingComposition = targetRoom.compatibleRoomCompositions.find(
          existingComposition =>
            areCompositionsEqual(existingComposition, composition),
        );
        if (!existingComposition) {
          targetRoom.compatibleRoomCompositions.push({ ...composition });
        } else if (depth > 0) {
          mergeCompatibleRoomCompositions(
            existingComposition,
            composition.compatibleRoomCompositions,
            depth - 1,
          );
        }
      });
    },
    [areCompositionsEqual],
  );

  const wrapAvailableRoomCompositions = useCallback(
    (mergedRooms, room, depth) => {
      const key = getRoomKey(room, depth);
      if (!mergedRooms.has(key)) {
        mergedRooms.set(key, { ...room });
      } else {
        mergeCompatibleRoomCompositions(
          mergedRooms.get(key),
          room.compatibleRoomCompositions,
          depth,
        );
      }
      if (depth > 0) {
        room.compatibleRoomCompositions.forEach(composition => {
          wrapAvailableRoomPossibilities(
            composition.compatibleRoomCompositions || [],
          );
        });
      }
    },
    // eslint-disable-next-line
    [mergeCompatibleRoomCompositions, getRoomKey],
  );

  const wrapAvailableRoomPossibilities = useCallback(
    availableRooms => {
      const mergedRooms = new Map();
      availableRooms.forEach(room => {
        wrapAvailableRoomCompositions(mergedRooms, room, rooms?.length - 1);
      });
      return Array.from(mergedRooms.values());
    },
    [wrapAvailableRoomCompositions, rooms?.length],
  );

  const removeDuplicateRooms = rooms => {
    const uniqueRoomsIndex = {};
    const uniqueRooms = rooms.filter(room => {
      const key = `${room.name}_${
        room?.supplierCode ? room?.supplierCode : room?.supplier
      }_${room.source}_${room.bedChoices[0].totalPriceWithPromo}_${tagsKey(
        room.bedChoices[0].tags,
      )}`;
      if (!uniqueRoomsIndex[key]) {
        uniqueRoomsIndex[key] = true;
        return true;
      }
      return false;
    });
    return uniqueRooms;
  };

  const groupPossibilities = useCallback(
    data => {
      const filteredPossibilities = [];
      const datas = cloneDeep(data.possibilities);
      let possibilitiesData = datas;
      if (
        selectedRooms?.length > 0 &&
        selectedRooms?.[0]?.supplier === 'Koob'
      ) {
        possibilitiesData = findRoomByRoomNumber(datas, selectedRooms?.length);
      }
      const originalPossibilities = possibilitiesData.map(
        (possibility, index) => {
          possibility.originalIndex = index;
          if (possibility.supplier === 'Koob') {
            filteredPossibilities.push(possibility);
          }

          return possibility;
        },
      );

      const groupedPossibilities = {};

      filteredPossibilities.forEach(possibility => {
        const key = `${possibility?.supplierSpecificInformations?.hotelRoomId}`;

        if (!groupedPossibilities[key]) {
          groupedPossibilities[key] = {
            ...possibility,
            allPromotions: {},
            originalIndexes: [],
          };
        }

        possibility.bedChoices.forEach(bedChoice => {
          if (
            bedChoice.promotionInfo.kind !== 'supplement' &&
            bedChoice.promotionInfo.kind !== 'supplement_percent'
          ) {
            if (
              !groupedPossibilities[key].allPromotions[
                bedChoice.id.split('##').pop()
              ]
            ) {
              groupedPossibilities[key].allPromotions[
                bedChoice.id.split('##').pop()
              ] = [{ [bedChoice.id]: bedChoice.promotions }];
            } else {
              groupedPossibilities[key].allPromotions[
                bedChoice.id.split('##').pop()
              ].push({
                [bedChoice.id]: bedChoice.promotions,
              });
            }
            if (
              groupedPossibilities[key].originalIndexes.indexOf(
                possibility.originalIndex,
              ) === -1
            ) {
              groupedPossibilities[key].originalIndexes.push(
                possibility.originalIndex,
              );
            }
          }
        });
      });
      const newPossibilities = Object.values(groupedPossibilities).map(
        possibility => {
          const allPromotions = Object.values(possibility.allPromotions).flat();
          delete possibility.allPromotions;
          return { ...possibility, allPromotions };
        },
      );

      newPossibilities.forEach(possibility => {
        originalPossibilities.forEach(originalPossibility => {
          if (
            true ===
            possibility?.originalIndexes?.includes(
              originalPossibility.originalIndex,
            )
          )
            originalPossibility.allPromotions = possibility.allPromotions;
        });
      });

      const remainingPossibilitiesKoedia = originalPossibilities.filter(
        possibility => possibility.supplier === 'Koedia',
      );

      const remainingPossibilitiesRakuten = originalPossibilities.filter(
        possibility => possibility.supplier === 'Rakuten',
      );

      const wrappedRemainingPossibilitiesKoedia =
        wrapAvailableRoomPossibilities(remainingPossibilitiesKoedia);

      const r = JSON.parse(JSON.stringify(newPossibilities));
      r.forEach(po => {
        po.compatibleRoomCompositions = [];
      });

      updateOriginalCompatibilityRoom(
        originalPossibilities,
        originalPossibilities,
      );
      updateGroupedCompatibilityRoom(newPossibilities, r);
      updateGroupedCompatibilityRoom(originalPossibilities, r);

      const newOriginalPossibilities = [
        ...newPossibilities,
        ...wrappedRemainingPossibilitiesKoedia,
        ...remainingPossibilitiesRakuten,
      ].sort((a, b) => a.originalIndex - b.originalIndex);

      const supplementTypes = ['supplement', 'supplement_percent'];
      const updatedPossibiliiesList = newOriginalPossibilities.map(item => {
        const composition = item;
        composition?.bedChoices.forEach(bedChoice => {
          if (supplementTypes.includes(bedChoice?.promotionInfo?.kind)) {
            bedChoice.totalPriceWithPromo = bedChoice.totalPriceWithoutPromo;
          }
        });
        return composition;
      });

      const cleanedPossibilities = removeDuplicateRooms(
        updatedPossibiliiesList,
      );
      return { original: originalPossibilities, new: cleanedPossibilities };
    },
    [
      updateGroupedCompatibilityRoom,
      updateOriginalCompatibilityRoom,
      wrapAvailableRoomPossibilities,
      selectedRooms,
    ],
  );

  const selectedRoomTotalPrice = (selectedRooms || []).reduce((total, room) => {
    return (
      total +
      (room?.optionalPromos?.reduce(
        (promoTotal, { price }) => promoTotal + price,
        0,
      ) || 0)
    );
  }, 0);

  const formatDataBasedOnSelection = (selectedRooms, possibility) => {
    let formattedData = {};

    for (let i = 0; i < selectedRooms.length; i++) {
      if (!possibility) {
        return formattedData;
      }
      formattedData = {
        possibilities: possibility?.compatibleRoomCompositions,
      };
    }

    return formattedData;
  };

  useEffect(() => {
    const canResetIndex =
      selectedRooms?.[0]?.supplier === 'Koedia' &&
      currentSelectedRoomIndex > initialPossibilities.length;
    if (canResetIndex) {
      setCurrentSelectedRoomIndex(0);
    }
  }, [initialPossibilities, currentSelectedRoomIndex, selectedRooms]);

  useEffect(() => {
    const isKoediaSupplier = selectedRooms?.[0]?.supplier === 'Koedia';
    const isRakutenSupplier = selectedRooms?.[0]?.supplier === 'Rakuten';
    if (data && data.data && selectedRooms.length === 0) {
      const response = cloneDeep(data?.data);
      const groupedPossibilities = groupPossibilities(response);

      setPossibilities(groupedPossibilities.new);
      const initialPossibilitiesSelected = isKoediaSupplier
        ? groupedPossibilities.new
        : groupedPossibilities.original;
      setInitialPossibilities(initialPossibilitiesSelected);
      setRequestId(data.data.requestId);
      setExpirationDate(data.data.expiresAt);
    }

    const formatDataInput = isKoediaSupplier ? possibilities : selectedRooms;

    if (selectedRooms.length > 0) {
      const result =
        isKoediaSupplier || isRakutenSupplier
          ? isKoediaSupplier
            ? formatDataInput
            : possibilities
          : cloneDeep(data?.data?.possibilities);

      const possibility = result?.find(item => {
        const lastSelectedRoom = selectedRooms.at(-1);

        return (
          item.name === lastSelectedRoom.name &&
          (!isKoediaSupplier ||
            tagsKey(item.bedChoices[0].tags) ===
              tagsKey(lastSelectedRoom.bedChoices[0].tags))
        );
      });

      if (possibility) {
        const updatedPossibilities = formatDataBasedOnSelection(
          formatDataInput,
          possibility,
        );
        const groupedPossibilities = groupPossibilities(updatedPossibilities);

        setPossibilities(groupedPossibilities.new);
        setInitialPossibilities(groupedPossibilities.original);
        setRequestId(data.data.requestId);
        setExpirationDate(data.data.expiresAt);
      }
    }
    // eslint-disable-next-line
  }, [
    data,
    setRequestId,
    setExpirationDate,
    groupPossibilities,
    selectedRooms,
  ]);

  function replaceIdentifier(id, newIdentifier) {
    return id.replace(/\+(\d+)\+/, `+${newIdentifier}+`);
  }
  const getCurrentSelectedRoom = () => {
    const bed =
      initialPossibilities[currentSelectedRoomIndex].bedChoices[
        currentSelectedBedIndex
      ];

      return {
      ...initialPossibilities[currentSelectedRoomIndex],
      bed: bed,
      roomIndex: currentSelectedRoomIndex,
      bedIndex: currentSelectedBedIndex,
      firstAdultNationality:
        filters?.rooms[selectedRooms.length].firstAdultNationality,
      adults: filters?.rooms[selectedRooms.length].adults,
      children: (filters?.rooms[selectedRooms.length].childrenBirthdates ?? [])
        .length,
      bedCompositionId: bed?.id,
      promotionId: selectedPromo?.promotionId,
      optionalPromos: optionalPromo,
    };
  };

  const validateRoom = () => {
    const currentSelectedRoom = getCurrentSelectedRoom();
    if (rooms?.length !== selectedRooms.length + 1) {
      setSelectedRooms(prev => [...prev, currentSelectedRoom]);
      setCurrentSelectedRoomIndex(currentSelectedPossibilityIndex);
      setCurrentSelectedBedIndex(0);
      return;
    }

    const isKoediaSupplier = selectedRooms?.[0]?.supplier === 'Koedia';
    const lastRoomID = currentSelectedRoom.bedCompositionId;
    const match = lastRoomID.match(/\+(\d+)\+/);
    const newIdentifier = match ? match[1] : null;
    const correctedRooms = isKoediaSupplier
      ? selectedRooms?.map(room => {
          return {
            ...room,
            bedCompositionId: replaceIdentifier(
              room?.bedCompositionId,
              newIdentifier,
            ),
          };
        })
      : selectedRooms;

    onSelectHotelsRooms([...correctedRooms, currentSelectedRoom]);
  };

  const handleChooseRoom = () => {
    const currentSelectedRoom = getCurrentSelectedRoom();
    const travelers = getTravelerComposition({
      compositions: [
        {
          adults:
            basketHotel.filters.experienceComposition[
              basketHotel?.selectedRooms?.length ?? 0
            ].adults,
          children:
            basketHotel.filters.experienceComposition[
              basketHotel?.selectedRooms?.length ?? 0
            ].childrenBirthdates.length,
        },
      ],
      customerFile: basketHotel.clientFolder,
      nationalities: nationalities,
    })[0].travelers;

    updateInBasket({
      ...basketHotel,
      selectedRooms: [
        ...(basketHotel.selectedRooms ?? []),
        { ...currentSelectedRoom, travelers: travelers },
      ],
      newRoom: true,
      requestId: data.data.requestId,
    });
    if (
      (basketHotel?.selectedRooms?.length
        ? basketHotel?.selectedRooms?.length + 1
        : 1) === basketHotel.filters?.experienceComposition?.length
    ) {
      navigate(`/bookings/trips/${tripFilters?.tripId}/checkout`, {
        replace: true,
      });
    }
  };

  const handleRoomIndexChange = (index, bedIndex) => {
    setCurrentSelectedRoomIndex(index);
    setCurrentSelectedBedIndex(bedIndex);
    setBedIndexes([]);
  };

  let totalPrice = 0;
  let totalPriceInDollar = 0;
  selectedRooms.forEach(room => {
    totalPrice += parseFloat(room?.bed?.totalPriceWithPromo);
    totalPriceInDollar += parseFloat(room?.bed?.totalPriceInDollar?.withPromo);
  });
  totalPrice += parseFloat(
    initialPossibilities[currentSelectedRoomIndex]?.bedChoices[
      currentSelectedBedIndex
    ].totalPriceWithPromo ?? 0,
  );

  const currency =
    initialPossibilities[currentSelectedRoomIndex]?.bedChoices[
      currentSelectedBedIndex
    ].currency;

  totalPriceInDollar += parseFloat(
    initialPossibilities[currentSelectedRoomIndex]?.bedChoices[
      currentSelectedBedIndex
    ].totalPriceInDollar?.withPromo ?? 0,
  );

  const prices =
    selectedRoomTotalPrice +
    optionalPromo.reduce((total, { price }) => total + price, 0);
  totalPrice += parseFloat(prices);

  const roomsForRecap = () => {
    const matchingRoom = initialPossibilities[currentSelectedRoomIndex];

    return [
      ...selectedRooms,
      ...(possibilities.length > 0
        ? [
            {
              ...matchingRoom,
              bed: initialPossibilities[currentSelectedRoomIndex]?.bedChoices[
                currentSelectedBedIndex ?? 0
              ],
            },
          ]
        : []),
    ];
  };

  const promoLists = roomsForRecap()[roomsForRecap()?.length - 1] || [];
  const promoSupp = promoLists?.bedChoices?.[0]?.optionalSupplements
    ?.map(supplement => {
      if (Number(supplement?.value) > 0) {
        return {
          id: supplement?.id,
          displayName: supplement?.name,
          value: supplement?.name,
          price: Math.round(supplement?.value, 100),
          priceInDollar: Math.round(supplement?.priceInDollar, 100),
          kind: supplement?.kind,
        };
      }
      return {};
    })
    .filter(item => Boolean(item));

  const modifyCurrentRooms = selectionIndex => {
    const roomSelected = selectedRooms[selectionIndex];
    const newRoomsSelected = selectedRooms.slice(0, selectionIndex);

    setSelectedRooms(newRoomsSelected);
    setCurrentSelectedRoomIndex(roomSelected.roomIndex);
    setCurrentSelectedBedIndex(roomSelected.bedIndex);
    setCurrentSelectedPossibilityIndex(roomSelected.roomIndex);
  };

  const updateRoomFromPromotion = (
    originalIndex,
    bedChoicePromo,
    bedName,
    selectedPossibility,
  ) => {
    const room = initialPossibilities
      .filter(possibility => {
        return possibility.originalIndex === originalIndex;
      })
      .pop();

    const rooms = initialPossibilities.filter(possibility => {
      return (
        room.supplierSpecificInformations.hotelRoomId ===
        possibility?.supplierSpecificInformations?.hotelRoomId
      );
    });

    let found = false;
    for (let possibility of rooms) {
      if (found) {
        break;
      }
      for (let i = 0; i < possibility.bedChoices.length; i++) {
        let bed = possibility.bedChoices[i];
        if (
          bed.name === bedName &&
          bed.promotions.join(', ') === bedChoicePromo
        ) {
          initialPossibilities[possibility.originalIndex].allPromotions =
            selectedPossibility.allPromotions;
          setCurrentSelectedRoomIndex(possibility.originalIndex);
          setCurrentSelectedBedIndex(i);
          found = true;
          break;
        }
      }
    }
  };

  const formattedPossibilities = formatHotelRoomPossibilities(possibilities);

  return (
    <Stack gutterSize={2}>
      <Stack direction="column" style={{ flex: 2 }} gutterSize={1}>
        <SectionTitle>{t('availableRooms')}</SectionTitle>

        <Content>
          {isLoading && possibilities?.length === 0 && (
            <KoobLoaderText
              title={t('hotel:loadingRooms.title')}
              description={t('hotel:loadingRooms.description')}
            />
          )}

          {isLoading && (
            <div className="mt-3 flex-col space-y-5">
              <KoobPlaceholder
                count={Math.max(0, 3 - possibilities?.length)}
                className="h-[220px] w-full rounded"
              />
            </div>
          )}

          {!isLoading && formattedPossibilities && (
            <Stack direction="column" size="full" gutterSize={1}>
              {formattedPossibilities.map((possibility, index) => {
                return (
                  <RoomComponent
                    key={'roomComponent_' + index}
                    room={possibility}
                    selectedPossibilityIndex={currentSelectedRoomIndex}
                    isSelected={
                      currentSelectedRoomIndex === possibility.originalIndex
                      || possibility.originalIndex === currentSelectedPossibilityIndex
                      || possibility.variants?.map(variant => variant.originalIndex).includes(currentSelectedRoomIndex)
                    }
                    bedIndex={
                      currentSelectedRoomIndex === possibility.originalIndex
                        ? currentSelectedBedIndex
                        : 0
                    }
                    updateSelectedRoom={handleRoomIndexChange}
                    numberOfNights={numberOfNights}
                    promoList={promoSupp}
                    setCurrentSelectedPossibilityIndex={setCurrentSelectedPossibilityIndex}
                    selectedRoomsCount={selectedRooms?.length}
                  />
                );
              })}
            </Stack>
          )}

          {!isLoading && possibilities && possibilities.length === 0 && (
            <Stack paddingTop={4} alignX="center">
              <Text color="textLight">{t('noPossibilities')}</Text>
            </Stack>
          )}
        </Content>
      </Stack>

      <Stack direction="column" style={{ flex: 1 }} gutterSize={1.5}>
        <DmcBookingHotelInfo filters={filters} hotel={hotel} />

        <DmcBookingHotelRoomsInfo
          rooms={rooms}
          numberOfNights={numberOfNights}
          roomsForRecap={roomsForRecap()}
          modifyCurrentRooms={modifyCurrentRooms}
          setCurrentSelectedBedIndex={setCurrentSelectedBedIndex}
          currentSelectedBedIndex={currentSelectedBedIndex}
          updateRoomFromPromotion={updateRoomFromPromotion}
          promoList={promoSupp}
          optionalPromo={optionalPromo}
          setOptionalPromo={setOptionalPromo}
          selectedRooms={selectedRooms}
          setSelectedPromo={setSelectedPromo}
        />

        <Stack direction="column" gutterSize={1} size="full">
          {!isBookingOnRequest && !isLastRoomOnRequest && (
            <Stack gutterSize={0.5}>
              <Text color="textLighter">
                <IoWarningOutline size={24} />
              </Text>
              <Text color="textLighter">{t('onRequest')}</Text>
            </Stack>
          )}

          <Button
            type="button"
            variant="primaryBig"
            size="full"
            disabled={possibilities.length === 0 || !currentSelectedRoom}
            onClick={basketHotel ? handleChooseRoom : validateRoom}
            id="confirm-room"
          >
            {basketHotel
              ? t('next')
              : selectedRooms?.length === rooms?.length - 1
              ? t('confirmRooms')
              : t('nextRoom')}
          </Button>
        </Stack>

        <Stack
          size="full"
          alignY="baseline"
          alignX="space-between"
          gutterSize={1}
        >
          <div className="w-full whitespace-nowrap">
            <Text type="h3" color="textLighter" fontWeight={400}>
              {t('totalPrice')}
            </Text>
          </div>

          <div className="flex space-x-1 whitespace-nowrap">
            {!isNaN(totalPriceInDollar) && totalPriceInDollar !== 0 && (
              <Text type="h3" color="primary" fontWeight={500}>
                {formatCurrency({
                  amount: totalPriceInDollar,
                  currency: 'usd',
                })}
                {' / '}
              </Text>
            )}

            {totalPrice !== 0 ? (
              <Text type="h3" color="primary" fontWeight={500}>
                {formatCurrency({
                  amount: totalPrice,
                  currency: currency,
                })}
              </Text>
            ) : (
              <Text type="h2" color="textLighter" fontWeight={400}>
                -
              </Text>
            )}
          </div>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SelectRoomsForm;

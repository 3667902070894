import KoobModal from '../../../components/Koob/KoobModal';
import { useFormikContext } from 'formik';
import KoobInputCheckbox from '../../../components/Koob/Input/KoobInputCheckbox';
import { useEffect, useState } from 'react';
import KoobTitle from '../../../components/Koob/KoobTitle';
import KoobInput from '../../../components/Koob/Input/KoobInput';
import KoobButton from '../../../components/Koob/KoobButton';
import KoobInputSelect from '../../../components/Koob/Input/KoobInputSelect';
import { useTranslation } from 'react-i18next';
import { applyAllotmentEdits } from './allotmentsUtils';
import KoobTabs from "../../../components/Koob/KoobTabs";
import { AllotmentDayStateEnum } from "@koob/enums";
import KoobAlert from "@koob/ui/src/components/KoobAlert";

function AllotmentsEditorEditModalMultiSelect({ select = 'all', onClick, disabled }) {
  const { t } = useTranslation('allotments');

  return (
    <div
      onClick={onClick}
      className={[
        'font-medium',
        !disabled ? 'cursor-pointer text-gray-500' : 'cursor-not-allowed text-gray-300',
      ].join(' ')}
    >
      {select === 'all' ? (
        <i className="far fa-check-square mr-1.5"/>
      ) : (
        <i className="far fa-square mr-1.5"/>
      )}
      {select === 'all' ? t('modal.selectAll') : t('modal.deselectAll')}
    </div>
  );
}

const tabs = [
  {
    id: 'allotments',
    label: 'Allotments',
  },
  {
    id: 'sharedAllotments',
    label: 'Shared Allotments',
  }
];

export default function AllotmentsEditorEditModal({ open, onClose, selectedOrganizationId, selectedDays, rooms, hasSharedAllotments }) {
  const { t } = useTranslation('allotments');
  const { setValues } = useFormikContext();

  const [tab, setTab] = useState('allotments');

  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [state, setState] = useState(null);
  const [count, setCount] = useState(null);

  const daysGroupedByRoom = selectedDays?.reduce((acc, curr) => {
    if (!acc[curr.roomId]) {
      acc[curr.roomId] = [];
    }

    acc[curr.roomId].push(curr.day);

    return acc;
  }, {});

  const selectedRooms = Array.from(
    new Set(
      selectedDays.map(day => day.roomId)
    )
  ).map(id => rooms.find(room => room.id === id));

  const organizations = Array.from(
    new Set(
      rooms?.flatMap(room => room.organizations.map(org => org.id))
    )
  ).map(id => {
    const org = rooms.flatMap(room => room.organizations).find(org => org.id === id);

    return {
      id: org.id,
      name: org.name,
      kind: org.kind,
    };
  }).sort((a, b) => a.kind === 'dmc' ? -1 : 1);

  const apply = () => {
    const newRooms = applyAllotmentEdits({
      rooms,
      selectedRooms,
      selectedOrganizations,
      selectedDays,
      shared: tab === 'sharedAllotments',
      state,
      count
    });

    setValues({ rooms: newRooms });

    reset();
    onClose();
  };

  const reset = () => {
    setSelectedOrganizations([
      organizations.find(org => parseInt(org.id) === selectedOrganizationId)
    ]);
    setState(null);
    setCount(null);
  };

  const showSharedAllotmentAlert = state === AllotmentDayStateEnum.ON_REQUEST && hasSharedAllotments;

  useEffect(() => {
    if (open || tab === 'sharedAllotments') {
      reset();
    }
  }, [open, tab]);

  const isDirty = selectedOrganizations.length > 1
    || (state !== null && state !== '')
    || (count !== null && count !== '');

  return (
    <KoobModal
      open={open}
      setOpen={() => onClose()}
      widthClass="sm:max-w-3xl"
    >
      <div className="p-5 flex-col space-y-8">
        <div>
          <KoobTitle>
            {t('modal.roomsTitle')}
          </KoobTitle>

          <div className="mt-5 flex-col space-y-3">
            {selectedRooms?.map(room => (
              <div
                key={room.id}
                className="font-medium text-gray-500"
              >
                <div className="flex space-x-1.5">
                  <div>
                    <i className="far fa-bed mr-1.5" />
                    {room.name}
                  </div>

                  <div>
                    &bull;
                  </div>

                  <div>
                    {daysGroupedByRoom[room.id].length} days
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="border rounded -mx-12">
          <KoobTabs
            tabs={tabs}
            currentTab={tab}
            setCurrentTab={setTab}
            disabled={isDirty}
          />
        </div>

        {tab === 'allotments' && (
          <div>
            <div className="flex space-x-3 items-center">
              <KoobTitle>
                {t('modal.organizationsTitle')}
              </KoobTitle>

              <AllotmentsEditorEditModalMultiSelect
                select={'all'}
                onClick={() => setSelectedOrganizations(organizations)}
                disabled={selectedOrganizations.length === organizations.length}
              />

              <AllotmentsEditorEditModalMultiSelect
                select={'none'}
                onClick={() => setSelectedOrganizations([])}
                disabled={selectedOrganizations.length === 0}
              />
            </div>

            <div className="mt-5 grid grid-cols-3 gap-5">
              {organizations?.map(org => (
                <KoobInputCheckbox
                  key={org.id}
                  value={selectedOrganizations?.some(o => o && o.id === org.id)}
                  setValue={() => setSelectedOrganizations(
                    selectedOrganizations?.some(o => o && o.id === org.id)
                      ? selectedOrganizations.filter(o => o && o.id !== org.id)
                      : [...selectedOrganizations, org]
                  )}
                  id={org.name?.split(' ').join('-').toLowerCase()}
                >
                  <div className="flex space-x-2 items-center">
                    <div className="leading-none text-sm text-gray-600 font-medium">
                      {org.kind === 'dmc' ? (
                        <div className="bg-k-orange px-3 py-1.5 text-white rounded-md">
                          <i className="far fa-building mr-1.5" />
                          {t('modal.dmc')}
                        </div>
                      ) : (
                        <div>{org.name}</div>
                      )}
                    </div>
                  </div>
                </KoobInputCheckbox>
              ))}
            </div>
          </div>
        )}

        {tab === 'allotments' && (
          <div>
            <KoobTitle>
              {t('modal.allotmentsTitle')}
            </KoobTitle>

            <div className="mt-5 flex space-x-3">
              <KoobInputSelect
                label={t('modal.state.label')}
                value={state}
                onChange={value => setState(value)}
                options={[
                  { label: '', value: null },
                  { label: t('modal.state.freeSales'), value: 'free_sale' },
                  { label: t('modal.state.stopSales'), value: 'stop_sale' },
                  { label: t('modal.state.onRequest'), value: 'on_request' },
                  { label: t('modal.state.allotments'), value: 'allotment' },
                ]}
              />

              <KoobInput
                label={t('modal.allotmentsCount')}
                value={count}
                onChange={value => setCount(parseInt(value))}
                type="number"
                min="0"
                name="allotment"
                disabled={state !== 'allotment'}
              />
            </div>
          </div>
        )}

        {tab === 'sharedAllotments' && (
          <div>
            <KoobTitle>
              {t('modal.sharedAllotmentsTitle')}
            </KoobTitle>

            <div className="mt-5 flex space-x-3">
              <KoobInput
                label={t('modal.sharedAllotmentsCount')}
                value={count}
                onChange={value => setCount(parseInt(value))}
                type="number"
                min="0"
                name="allotment"
              />
            </div>
          </div>
        )}

        {showSharedAllotmentAlert && (
          <KoobAlert
            icon={'fa-bolt'}
            title={t('modal.sharedAllotmentsAlert.title')}
            description={t('modal.sharedAllotmentsAlert.description')}
            variant="warning"
          />
        )}

        <div>
          <KoobButton
            onClick={() => apply()}
            disabled={selectedOrganizations.length === 0}
            id="apply-config"
          >
            {tab === 'allotments'
              ? t('modal.applyButton', { count: selectedOrganizations.length })
              : t('modal.applyButtonShared')
            }
          </KoobButton>
        </div>
      </div>
    </KoobModal>
  );
}

import { DataTable, Button, Header, Breadcrumb } from 'components';
import { IcPencil, IcArchive } from 'components/icons';
import { ActionsLinks, ActionLink, Container } from 'ui';
import { useTranslation } from 'react-i18next';
import {
  getActionsCellWidth,
} from 'utils';
import {
  Stack,
} from '@koob/margaret';
import { get } from "../../../api/node";
import { useAsync } from "react-async";
import ApiKeyPermissions from "./Partials/ApiKeyPermissions";
import ApiKeyOrganizations from "./Partials/ApiKeyOrganizations";
import { KoobBadge } from "@koob/ui";

export const getApiKeys = async () => {
  return await get(`/organization-api-keys`);
};

const ApiKeyList = () => {
  const { t } = useTranslation('organizationApiKeys');

  const { data } = useAsync({
    promiseFn: getApiKeys,
  });

  const isEmpty = data?.data && data.data.length === 0;

  const headings = [
    {
      slug: 'id',
      label: t('id'),
      width: '5%',
    },
    {
      slug: 'apiKey',
      label: t('apiKey'),
      width: '30%',
    },
    {
      slug: 'permissions',
      label: t('permissions'),
      width: '25%',
      allowOverflow: true,
    },
    {
      slug: 'organizations',
      label: t('organizations'),
      width: '35%',
      allowOverflow: true,
    },
    { slug: 'actions', cannotBeReordered: true, width: getActionsCellWidth(2) },
  ];

  const rows = data?.data?.map(
    (node) => ({
      id: { value: node?.id, },
      apiKey: {
        render: () => (
          <div className="w-full">
            <div>
              { node?.name }
            </div>

            <div className="mt-1 flex space-x-3 items-center">
              <div className="flex">
                <KoobBadge size="sm">
                  <span className="uppercase">{node?.type}</span>
                </KoobBadge>
              </div>

              <div className="text-sm font-medium text-k-orange">
                <i className="far fa-key mr-1.5" />
                {node?.apiKey ? `${node.apiKey.slice(0, 5)}...${node.apiKey.slice(-5)}` : ""}
              </div>
            </div>
          </div>
        )
      },
      permissions: {
        render: () => <ApiKeyPermissions permissions={node?.roles} />
      },
      organizations: {
        render: () => <ApiKeyOrganizations organizations={node?.organizations} />,
      },
      actions: {
        render: () =>
          !node.archivedAt && (
            <ActionsLinks>
              <ActionLink to={`${node?.id}/edit`}>
                <IcPencil />
              </ActionLink>
              <ActionLink to={`${node?.id}/archive`}>
                <IcArchive />
              </ActionLink>
            </ActionsLinks>
          ),
      },
    }),
  ) ?? [];

  return (
    <>
      <Header
        rightElement={
          <Button
            icon={<i className="far fa-plus mr-1.5" />}
            variant="primary"
            to="new"
          >
            {t('new')}
          </Button>
        }
      />

      <Container variant="main">
        <Stack direction="column" size="full" alignX="center" gutterSize={1}>
          <DataTable
            headings={headings}
            data={rows}
            isEmpty={isEmpty}
            isSearchable={false}
          />
        </Stack>
      </Container>
    </>
  );
};

export default ApiKeyList;

import { useNavigate, useParams } from 'react-router-dom';
import { Container, Card } from 'ui';
import { useSnack, useError } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Header } from 'components';
import ApiKeyForm from "./Partials/ApiKeyForm";
import { useAsync } from "react-async";
import { get, put } from "../../../api/node";

export const getApiKey = async ({ id }) => {
  return await get(`/organization-api-keys/${id}`);
};

export const updateApiKey = async ({ id, data }) => {
  return await put(`/organization-api-keys/${id}`, data);
}

export default function ApiKeyEdit() {
  const { apiKeyId } = useParams();
  const { t } = useTranslation('organizationApiKeys');
  const navigate = useNavigate();
  const { notify } = useSnack();
  const { sendErrorSnack } = useError();

  const { data } = useAsync({
    promiseFn: getApiKey,
    id: apiKeyId,
  });

  const handleUpdateApiKey = async (values) => {
    try {
      await updateApiKey({
        id: apiKeyId,
        data: {
          ...values,
          organizationIds: values.organizationIds.map(({ value }) => value),
        }
      });

      navigate('/organization-api-keys');
      notify(t('editSuccess'));
    } catch (err) {
      sendErrorSnack(err);
    }
  };

  return (
    <>
      <Header />

      <Container size="narrow">
        <Card>
          <ApiKeyForm
            onSubmit={handleUpdateApiKey}
            apiKey={data?.data}
          />
        </Card>
      </Container>
    </>
  );
};

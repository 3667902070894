import { Stack } from '@koob/margaret';
import { GoogleMap } from '@react-google-maps/api';
import EmptyState from 'components/EmptyState';
import { Paginator } from 'components/Paginator';
import Spinner from 'components/Spinner';
import { useSearchParams } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from 'react-use';
import styled from 'styled-components';
import { Container } from 'ui';
import ExperienceCard from './ExperienceCard';
import ExperienceMarker from './ExperienceMarker';
import { viewKind } from 'utils';

const Sidebar = styled.div`
  background-color: #ffffff;
  width: 380px;
  box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.12);
  max-height: calc(100vh - ${({ offsetTop }) => offsetTop}px);
  overflow-y: auto;
`;

const ExperiencesGrid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: ${({ theme }) => theme.spacing(1.5)};
  grid-template-columns: 100%;
  grid-template-rows: auto;
  height: 100%;
`;

const Wrapper = styled(Stack)`
  padding-top: ${({ offsetTop }) => offsetTop}px;
`;

const MapWrapper = styled.div`
  position: fixed;
  top: ${({ offsetTop }) => offsetTop}px;
  left: calc(${({ theme }) => theme.mainNav.width} + 380px);
  right: 0;
  bottom: 0;
`;

const ExperiencesMap = ({
  experiences,
  isLoading,
  headerHeight,
  isEmpty,
  page,
  setPage,
  totalPages,
  perPage,
  setPerPage
}) => {
  const [map, setMap] = useState();
  const [isPristine, setIsPristine] = useState(true);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('experiences');

  const onLoad = mapInstance => {
    if (!Boolean(map)) {
      setMap(mapInstance);
    }
  };

  useDeepCompareEffect(() => {
    if (!Boolean(map) || !isPristine || experiences.length === 0) {
      return;
    }

    const bounds = new window.google.maps.LatLngBounds();
    experiences
      .filter(
        experience =>
          Boolean(experience?.inCity?.lat) || Boolean(experience?.inCity?.lon),
      )
      .forEach(experience =>
        bounds.extend(
          new window.google.maps.LatLng(
            parseFloat(experience?.inCity?.lat),
            parseFloat(experience?.inCity?.lon),
          ),
        ),
      );
    map.fitBounds(bounds);
  }, [{ experiences, map }]);

  const onUnmount = () => {
    setMap(null);
  };

  useDeepCompareEffect(() => {
    setIsPristine(true);
  }, [{ ...searchParams }]);

  return (
    <Wrapper offsetTop={headerHeight}>
      <Sidebar offsetTop={headerHeight}>
        <Container>
          <ExperiencesGrid>
            {experiences.map(experience => (
              <ExperienceCard experience={experience} key={experience?.id} />
            ))}
            {isEmpty && (
              <EmptyState variant="full">{t('emptyStateMessage')}</EmptyState>
            )}
          </ExperiencesGrid>

          {!isEmpty && (
            <Stack paddingTop={1} alignX="center" size="full">
              <Paginator
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                perPage={perPage}
                setPerPage={setPerPage}
                kind={viewKind.MAP}
              />
            </Stack>
          )}
        </Container>
      </Sidebar>

      <MapWrapper offsetTop={headerHeight}>
        {isLoading && <Spinner />}

        <GoogleMap
          mapContainerStyle={{ height: `calc(100vh - 126px)`, width: '100%' }}
          options={{
            maxZoom: 14,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
          }}
          zoom={12}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {experiences.map(experience => (
            <ExperienceMarker experience={experience} key={experience?.id} />
          ))}
        </GoogleMap>
      </MapWrapper>
    </Wrapper>
  );
};

export default ExperiencesMap;
